//Polyfill
import "core-js";

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;
import jQuery from "jquery";

const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

$(document).ready(function() {
  // .header-btnがクリックされたときの処理
  $(".header-btn").click(function() {
    // .header-btnと.header-spのopenクラスをトグル（追加/削除）
    $(".header-btn, .header-sp").toggleClass("open");
  });

  // .header-sp内のaタグがクリックされたときの処理
  $(".header-sp a").click(function() {
    // .header-btnと.header-spからopenクラスを削除
    $(".header-btn, .header-sp").removeClass("open");
  });
});

const swiper = new Swiper(".top-slider", {
  slidesPerView: 1.2,
  loop: true,
  spaceBetween: 10,
  allowTouchMove: false,
  speed: 6000,
  breakpoints: {
    768: {
      initialSlide: 3, // スライドの開始位置
      slidesPerView: 3.1,
      spaceBetween: 48,
    }
  },
  autoplay: {
    delay: 0,
  },
});

const addOddEvenClass = ()=>{
  //対象要素を取得（ここでは#listの子要素を取得）
  const listItem = document.querySelectorAll(".swiper-wrapper .swiper-slide");
  //子要素の数だけ繰り返し処理
  Array.from(listItem).forEach((item, index) => {
    //indexは0から始まるためインクリメント
    index++;
    //indexの奇数・偶数を判定
    if((index % 2) === 0){
      //偶数番目の要素にclassを付加する
      item.classList.add("even");
    } else {
      //奇数番目の要素にclassを付加する
      item.classList.add("odd");
    }
  });
}
addOddEvenClass();


const swiper = new Swiper(".event-slider", {
  slidesPerView: 1.5,
  loop: true,
  spaceBetween: 10,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 2000,
  },
  breakpoints: {
    768: {
      slidesPerView: 4,
      spaceBetween: 30,
    }
  }
});